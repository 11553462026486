jQuery(document).ready(function($){
    $('#featured_slider_selector').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000, // 3 seconds
        responsive: [
            {
                breakpoint: 768, // Mobile breakpoint
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    $('#area-filter').select2({
        placeholder: "Select your areas",
        allowClear: true,
        templateResult: function(state) {
            if (!state.id) {
                return state.text;
            }
            var $state = $(
                '<span>' + state.text + '</span>'
            );
            if ($(state.element).is(':disabled')) {
                $state.addClass('greyed-out');
            }
            return $state;
        }
    });

    // Apply custom styles for greyed-out options
    $('.greyed-out').css('color', 'grey');
    
    // Listen for change event on select element
    $('#area-filter').on('change', function() {
        // Clear existing tags in the selected__tags div
        $('.selected__tags').empty();

        // Loop through each selected option and move it to the selected__tags div
        $('#area-filter option:selected').each(function() {
            var selectedTag = $('<span class="selected-tag">' + $(this).text() + '</span>');
            $('.selected__tags').append(selectedTag);
        });
    });
});